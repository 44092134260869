import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { Section, Title, Text, Box, ButtonIcon } from '../../components/Core'
import PageWrapper from '../../components/PageWrapper'
import Screenshot from '../../components/Screenshot'
import NextLink from '../../components/NextLink'
import WebsiteLink from '../../components/WebsiteLink'
import Slides from '../../components/Slides'
import Contact from '../../sections/common/Contact'
import Hero from '../../sections/common/Hero'
import { Keys, Key } from '../../sections/keys/Keys'

import rig from '../../assets/images/rp/rig.png'
import favicon from '../../assets/images/rp/favicon.png'

import devices from '../../assets/images/rp/device-screens.png'

import account from '../../assets/images/rp/account.jpg'
import billing from '../../assets/images/rp/billing.jpg'
import homepage from '../../assets/images/rp/homepage.jpg'
import listing from '../../assets/images/rp/listing.jpg'

const WorkSingle = () => {
    return (
        <>
            <PageWrapper>
                <Hero className="mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg="7">
                                <Text variant="tag">FULL STACK</Text>
                                <Title mb="0.5em">Rig Planet</Title>
                                <Text variant="p" mb="1.6em">
                                    The Rig Planet project was an ambitious full
                                    stack development project. The service is a
                                    marketplace for heavy equipment owners to
                                    buy and sell used equipment.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    Our goal was to create a mobile first
                                    responsive marketplace complete with account
                                    and advertising management admin tools.
                                </Text>
                            </Col>
                            <Col lg="5" style={{ alignSelf: 'center' }}>
                                <img
                                    src={rig}
                                    alt="Rig Planet Bulldozer"
                                    className="img-fluid w-100"
                                />
                            </Col>
                        </Row>
                    </Container>
                </Hero>

                <div className="mt-lg-3">
                    <Container>
                        <Row>
                            <Col lg="4" className="mb-4 mb-lg-0">
                                <Text variant="tag">Client</Text>
                                <Title variant="cardBig" className="mt-3">
                                    Doc Monical
                                </Title>
                            </Col>
                            <Col lg="4" className="mb-4 mb-lg-0">
                                <Text variant="tag">Time</Text>
                                <Title variant="cardBig" className="mt-3">
                                    May 2014
                                </Title>
                            </Col>
                            <Col lg="4">
                                <Text variant="tag">Website</Text>

                                <Title variant="cardBig" className="mt-3">
                                    <WebsiteLink
                                        src={favicon}
                                        href="https://www.rigplanet.com"
                                    >
                                        rigplanet.com
                                    </WebsiteLink>
                                </Title>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Section className="mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg="6" className="mb-5 pr-lg-5">
                                <img
                                    src={devices}
                                    alt="Rig Planet Device Screens"
                                    className="img-fluid w-100"
                                />
                            </Col>
                            <Col lg="6" className="mb-5 pl-lg-5">
                                <Title variant="cardBig" className="mb-4">
                                    Built to Last
                                </Title>
                                <Text variant="p" mb="1.6em">
                                    There wasn't a lot of ecommerce marketplaces
                                    for heavy equipment back in 2014. I set out
                                    to build a complete self managed ecom
                                    platform that was simple to use and could be
                                    easily managed by the owner.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    The entire websites interfaces were built as
                                    mobile first responsive because I was
                                    confident it was the only way to build a
                                    site to last because mobile devices were
                                    rapidly evolving.
                                </Text>
                                <Text variant="p" mb="1.6em"></Text>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Keys mt="-5%">
                    <Key name="symfony" title="01. Symfony" left>
                        In 2014, symfony was a fast evolving PHP framework that
                        I began building reuseable boilerplate websites with.
                        Symfony was chosen for this framework because of its
                        adoption of an ORM called propel that made it easy to
                        work with relationships in databases.
                    </Key>

                    <Key name="elastic" title="02. Elastic Search">
                        Relatively new to Amazon Web Services cloud was Elastic
                        Search. It didn't take long to realize the speed and
                        effeciency of indexing site searches to provide nearly
                        instant search results.
                    </Key>

                    <Key name="stripe" title="03. Stripe" left>
                        Payment Gateways at the time were incredible complicated
                        and the client wanted simple. Stripe provided a really
                        easy interface for managing billing and recurring
                        payments. The stripe api was a perfect match and the
                        client has enjoyed a hassle free payment gateway since
                        the site launched.
                    </Key>

                    <Key name="aws" title="04. Amazon Web Services">
                        I was not happy managing websites and databases through
                        products like Cpanel and ftp sites. When AWS became
                        widely available I was an early adopter because of the
                        variety of enterprise class cloud services. EC2, S3,
                        RDS, Elastic Search and many more were used in this
                        platform.
                    </Key>
                </Keys>

                <Section mt="22%" bg="dark">
                    <Container>
                        <Row
                            css={`
                                margin-top: -35%;
                            `}
                        >
                            <Col xs="12" className="mb-5">
                                <Slides>
                                    <Screenshot
                                        src={homepage}
                                        alt="Rig Planet Homepage"
                                    />
                                    <Screenshot
                                        src={account}
                                        alt="Rig Planet Account"
                                    />

                                    <Screenshot
                                        src={billing}
                                        alt="Rig Planet Billing"
                                    />
                                    <Screenshot
                                        src={listing}
                                        alt="Rig Planet Listing"
                                    />
                                </Slides>
                            </Col>
                        </Row>

                        <div className="text-center mt-lg-5">
                            <Text
                                variant="tag"
                                className="mb-1"
                                color="lightShade"
                            >
                                Next Project
                            </Text>
                            <NextLink to="/works/atlantic-spine-center/">
                                Atlantic Spine Center
                            </NextLink>
                        </div>
                    </Container>
                </Section>

                <Box py={4}>
                    <Contact />
                </Box>
            </PageWrapper>
        </>
    )
}
export default WorkSingle
